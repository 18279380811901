import RogueEnvironment from "../../assets/con/RogueEnvironment16x16-extruded.png";
import RoguePlayer from "../../assets/con/RoguePlayer_48x48.png";
import RogueCaptain from "../../assets/con/RogueCaptain_48x48.png";
import RogueInmate from "../../assets/con/RogueInmate_48x48.png";
import RogueItems from "../../assets/con/RogueItems16x16.png";

import Util from "../../assets/Util.png";

type AnimConfig = {
  key: string;
  frames: Phaser.Types.Animations.GenerateFrameNumbers;
  defaultTextureKey?: string;
  frameRate?: integer;
  duration?: integer;
  skipMissedFrames?: boolean;
  delay?: integer;
  repeat?: integer;
  repeatDelay?: integer;
  yoyo?: boolean;
  showOnStart?: boolean;
  hideOnComplete?: boolean;
};

type GraphicSet = {
  name: string;
  width: number;
  height: number;
  file: string;
  margin?: number;
  spacing?: number;
};

type AnimSet = GraphicSet & {
  animations: { [k: string]: AnimConfig };
};

const environment = {
  name: "environment",
  width: 16,
  height: 16,
  margin: 1,
  spacing: 2,
  file: RogueEnvironment,
  indices: {
    floor: {
      blueClean: [0x05],
      greyClean: [0x0d],
      greyScattered: [0x0d, 0x0d, 0x0d, 0x1d, 0x0f, 0x1f]
    },
    block: 0x17,
    doors: {
      horizontal: 0x81,
      vertical: 0x92,
      destroyed: 0x37
    },
    celldoors: {
      horizontal: 0x91,
      vertical: 0x92,
      destroyed: 0x37
    },
    walls: {
      alone: 0x14,
      intersections: {
        e_s: 0x00,
        n_e_s_w: 0x01,
        e_w: 0x02,
        s_w: 0x03,
        n_e_s: 0x10,
        w: 0x11,
        e: 0x12,
        n_s_w: 0x13,
        n_s: 0x20,
        s: 0x21,
        e_s_w: 0x22,
        n_e: 0x30,
        n_e_w: 0x31,
        n: 0x32,
        n_w: 0x33,
        e_door: 0x65,
        w_door: 0x67,
        e_door_sensor: 0x99,
        w_door_sensor: 0x9B,
      }
    },
    counters: {
      alone: 0x14,
      intersections: {
        e_w: 0x9C,
        n_w: 0x9D,
        s: 0x9E,
      }
    },
    bed: {
      top: 0x88,
      bottom: 0x98
    },
    crate: {
      double: 0x88,
      single: 0x97
    }
  }
};

const player: AnimSet = {
  name: "player",
  width: 48,
  height: 48,
  file: RoguePlayer,
  animations: {
    idle: {
      key: "playerIdle",
      frames: { start: 0x01, end: 0x07 },
      frameRate: 6,
      repeat: -1
    },
    idleBack: {
      key: "playerIdleBack",
      frames: { start: 0x0a, end: 0x11 },
      frameRate: 6,
      repeat: -1
    },
    walk: {
      key: "playerWalk",
      frames: { start: 0x14, end: 0x19 },
      frameRate: 10,
      repeat: -1
    },
    walkBack: {
      key: "playerWalkBack",
      frames: { start: 0x1e, end: 0x23 },
      frameRate: 10,
      repeat: -1
    },
  }
};

const captain: AnimSet = {
  name: "captain",
  width: 48,
  height: 48,
  file: RogueCaptain,
  animations: {
    idle: {
      key: "captainIdle",
      frames: { start: 0x01, end: 0x07 },
      frameRate: 6,
      repeat: -1
    }
  }
};

const inmate: AnimSet = {
  name: "inmate",
  width: 48,
  height: 48,
  file: RogueInmate,
  animations: {
    idle: {
      key: "inmateIdle",
      frames: { start: 0x01, end: 0x07 },
      frameRate: 6,
      repeat: -1
    }
  }
};

const items = {
  name: "items",
  width: 16,
  height: 16,
  file: RogueItems
};

const util = {
  name: "util",
  width: 16,
  height: 16,
  file: Util,
  indices: {
    black: 0x00
  }
};

export default {
  environment,
  player,
  captain,
  inmate,
  items,
  util
};
