import Phaser, { GameObjects, Types, Sound } from "phaser";
import Fonts from "../assets/Fonts";
import DungeonScene from "./DungeonScene";

import WorkflowLateSound from "../../assets/audio/late.mp3";
import { levels } from "../LevelData";

export default class InfoScene extends Phaser.Scene {
  checksToDo: number;
  checksCompleted: number;
  levelNum: number;
  levelName: string;

  checksCompletedLabel: GameObjects.BitmapText | null;
  countdownLabel: GameObjects.BitmapText | null;
  speakingTextLabel: GameObjects.BitmapText | null;
  levelLabel: GameObjects.BitmapText | null;
  timedEvent: Types.Time.TimerEventConfig | null;
  lateSound: Sound.BaseSound | null;
  timeTillLate: number | null;
  leaderBoard: any;

  constructor() {
    super("InfoScene");
    this.checksCompletedLabel = null;
    this.countdownLabel = null;
    this.speakingTextLabel = null;
    this.levelLabel = null;
    this.timedEvent = null;
    this.lateSound = null;
    this.timeTillLate = null;
    this.checksToDo = 0;
    this.checksCompleted = 0;
    this.levelNum = 0;
    this.levelName = "";
  }

  preload(): void {
    this.load.audio('late', WorkflowLateSound);
    this.load.bitmapFont("default", ...Fonts.default);
  }

  init(data: LevelData): void {
    this.timeTillLate = data.timeGiven;
    this.checksToDo = data.checksToDo;
    this.checksCompleted = data.checksCompleted;
    this.levelNum = data.levelNum;
    this.levelName = data.levelName;
  }

  create(): void {
    this.addOverlay();

    this.lateSound = this.sound.add("late");

    this.scene.get('DungeonScene').events.on('changeChecksCompleted', (c: number) => this.changeChecksCompleted(c));

    this.timedEvent = this.time.addEvent({ delay: 1000, callback: this.onTimedEvent, callbackScope: this, loop: true });

    this.leaderBoard = this.scene.scene.plugins.get('FirebasePlugin').add.leaderBoard({ root: 'mainLeaderboard' });

    const currentUser = firebase.auth().currentUser;
    this.leaderBoard.setUser(currentUser.uid, currentUser.displayName);
  }

  changeChecksCompleted(change: number) {
    this.checksCompleted = this.checksCompleted + change;

    if (this.checksCompletedLabel) this.checksCompletedLabel.text = `Checks Completed: ${this.checksCompleted} of ${this.checksToDo}`;
    if (this.checksCompleted === this.checksToDo) this.levelWin();
  }

  //Score is parseFloat(`${levelnumber}.${timeleft.toString().padStart(3, '0')}1`)

  //TODO: Store IP Address
  //TODO: Store Email Address

  gameOver() {
    const dungeonScene: DungeonScene = (this.scene.get('DungeonScene') as DungeonScene);
    dungeonScene.scene.pause();

    this.cameras.main.setBackgroundColor('rgba(100,100,100,0.35)');

    this.showSpeakingText("You're late!", 0xF4220D);

    this.lateSound?.play({
      volume: 1.0,
      loop: false,
    });
    if (this.timedEvent) this.timedEvent.paused = true;
    if (dungeonScene.tilemap) dungeonScene.add.image(
      dungeonScene.tilemap.tileToWorldX(dungeonScene.levelData.bigScreenXTile) + dungeonScene.levelData.bigScreenXOffset,
      dungeonScene.tilemap.tileToWorldY(dungeonScene.levelData.bigScreenYTile) + dungeonScene.levelData.bigScreenYOffset,
      "bigworkflowsscreenlate"
    ).setDepth(10);

    this.leaderBoard.getScore().then((oldScore: any) => {
      if (this.timeTillLate != null) {
        const newScore = parseFloat(`${this.levelNum}.${this.timeTillLate.toString().padStart(3, '0')}1`);

        if (oldScore) {
          this.setTitle("GAME OVER", `Your got to level ${this.scoreToLevel(newScore)} with ${this.scoreToTimeLeft(newScore)} seconds left. Last time you reached level ${this.scoreToLevel(oldScore["score"])} with ${this.scoreToTimeLeft(oldScore["score"])} seconds left`);

          if (newScore > oldScore["score"]) {
            this.showUpdateScore(newScore);
            this.showEndButtons(95);
          } else {
            this.showEndButtons(30);
          }
        } else {
          this.setTitle("GAME OVER", `Your got to level ${this.scoreToLevel(newScore)} with ${this.scoreToTimeLeft(newScore)} seconds left.`);

          this.showUpdateScore(newScore);
          this.showEndButtons(95);
        }
      }

    }).catch((e: any) => { console.log(e) });
  }

  scoreToLevel(score: number): number {
    return parseInt(score.toString().slice(0, -1).split(".")[0]);
  }

  scoreToTimeLeft(score: number): number {
    return parseInt(score.toString().slice(0, -1).split(".")[1]);
  }

  gameWin(newScore: number) {
    console.log(newScore);

    this.cameras.main.setBackgroundColor('rgba(100,100,100,0.35)');

    this.leaderBoard.getScore().then((oldScore: any) => {
      const newScore = parseFloat(`${this.levelNum}.${this.timeTillLate.toString().padStart(3, '0')}1`);

      console.log(oldScore);

      if (this.timeTillLate != null) {
        if (oldScore) {
          this.setTitle("YOU WON!", `Your got to level ${this.scoreToLevel(newScore)} with ${this.scoreToTimeLeft(newScore)} seconds left. Last time you reached level ${this.scoreToLevel(oldScore["score"])} with ${this.scoreToTimeLeft(oldScore["score"])} seconds left`);

          if (newScore > oldScore["score"]) {
            this.showUpdateScore(newScore);
            this.showEndButtons(95);
          } else {
            this.showEndButtons(30);
          }
        } else {
          this.setTitle("YOU WON!", `Your got to level ${this.scoreToLevel(newScore)} with ${this.scoreToTimeLeft(newScore)} seconds left.`);

          this.showUpdateScore(newScore);
          this.showEndButtons(95);
        }
      }

    }).catch((e: any) => { console.log(e) });

    if (this.timedEvent) this.timedEvent.paused = true;

    const dungeonScene: DungeonScene = (this.scene.get('DungeonScene') as DungeonScene);
    dungeonScene.scene.pause();
  }

  levelWin() {
    if (this.timedEvent) this.timedEvent.paused = true;
    if (this.timeTillLate != null) {
      const dungeonScene = this.scene.get('DungeonScene') as DungeonScene;
      if (levels.length === this.levelNum + 1) {
        console.log(`${this.timeTillLate}`);
        this.gameWin(parseFloat(`${this.levelNum}.${this.timeTillLate.toString().padStart(3, '0')}1`));
      } else {
        dungeonScene.scene.restart({ levelNumber: this.levelNum + 1 });
      }
    }
  }

  addInput(x: number, y: number, hint: string) {
    const inputWidth = 205;
    const inputHeight = 45;
    const inputBorder = 5;

    return this.add.rexInputText(
      x,
      y,
      inputWidth,
      inputHeight,
      {
        type: 'text',
        fontSize: "20px",
        align: 'center',
        placeholder: hint,
        backgroundColor: "#ffffff",
        outline: `#ffffff solid ${inputBorder}px`,
        color: '#000000',
      }
    );
  }

  addButton(x: number, y: number, text: string, onClick: Function, rectangleWidth: number = 360): GameObjects.Group {
    const rectangleHeight = 50;
    const rectangleBorder = 5;

    const newButton = this.add.rectangle(
      x,
      y,
      rectangleWidth,
      rectangleHeight,
      0xFFFFFF,
      0.0,
    );

    newButton.setDepth(10);
    newButton.setStrokeStyle(5, 0xFFFFFF);
    newButton.setInteractive({ useHandCursor: true });
    newButton.on('pointerdown', onClick);
    newButton.on('pointerover', () => newButton.fillAlpha = 0.2);
    newButton.on('pointerout', () => newButton.fillAlpha = 0.0);

    const singlePlayerText = this.add.bitmapText(
      x,
      y,
      "default",
      text,
      15
    );

    singlePlayerText.x = x - singlePlayerText.width / 2;
    singlePlayerText.y = y - singlePlayerText.height / 2 + rectangleBorder;
    singlePlayerText.setDepth(9);

    return new GameObjects.Group(this, [newButton, singlePlayerText]);
  }

  setTitle(title: string, subtitle: string) {
    const titleText = this.add.bitmapText(
      this.cameras.main.centerX,
      this.cameras.main.centerY,
      "default",
      title,
      60
    );

    titleText.x = this.cameras.main.centerX - titleText.width / 2;
    titleText.y = this.cameras.main.centerY - titleText.height / 2 - 120;
    titleText.setDepth(10);

    const subTitleText = this.add.bitmapText(
      this.cameras.main.centerX,
      this.cameras.main.centerY,
      "default",
      subtitle,
      15
    );

    subTitleText.x = this.cameras.main.centerX - subTitleText.width / 2;
    subTitleText.y = this.cameras.main.centerY - subTitleText.height / 2 - 80;
    subTitleText.setDepth(10);
  }

  showUpdateScore(newScore: number) {
    const saveToLeaderboardButtonGroup = this.addButton(
      this.cameras.main.centerX,
      this.cameras.main.centerY + 30,
      "Save to leaderboard",
      () => {
        saveToLeaderboardButtonGroup.getChildren().forEach((c: GameObjects.GameObject) => c.destroy());

        const nameInput = this.addInput(
          this.cameras.main.centerX - 375,
          this.cameras.main.centerY + 30,
          "Your Name",
        );

        const agencyInput = this.addInput(
          this.cameras.main.centerX - 125,
          this.cameras.main.centerY + 30,
          "Your Agency",
        );

        const emailInput = this.addInput(
          this.cameras.main.centerX + 125,
          this.cameras.main.centerY + 30,
          "Your Email (For prizes)",
        );

        this.leaderBoard.getScore().then((s: any) => {
          if (s) agencyInput.text = s["agencyName"]
          if (s) nameInput.text = s["userName"]
          if (s) emailInput.text = s["userEmail"]
        });

        this.addButton(
          this.cameras.main.centerX + 375,
          this.cameras.main.centerY + 30,
          "Save",
          () => {
            this.submitScore(nameInput.text, emailInput.text, agencyInput.text, newScore)
          },
          205
        );
      }
    );
  }

  showEndButtons(offsetY: number) {
    const dungeonScene = this.scene.get('DungeonScene').scene;
    this.addButton(
      this.cameras.main.centerX,
      this.cameras.main.centerY + offsetY,
      "Restart game",
      () => {
        dungeonScene.restart({ levelNumber: 1 });
        this.scene.stop();
      },
    );
    this.addButton(
      this.cameras.main.centerX,
      this.cameras.main.centerY + offsetY + 65,
      "Go to title screen",
      () => {
        this.scene.stop();
        dungeonScene.switch('TitleScene');
      },
    );
  }

  validateEmail(email: string) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) return true;

    return false;
  }

  submitScore(userName: string, userEmail: string, agencyName: string, score: number) {
    const currentUser = firebase.auth().currentUser;

    if (currentUser && userName != "" && this.validateEmail(userEmail) && agencyName != "") {
      currentUser.updateProfile({ displayName: userName });

      this.leaderBoard.setUser(currentUser.uid, userName);
      this.leaderBoard.post(score, { 'agencyName': agencyName, 'userEmail': userEmail }, undefined).then((r: any) => { console.log(r) }).catch((e: any) => { console.log(e) });
      this.scene.stop();
      this.scene.get('DungeonScene').scene.switch('TitleScene');
    }
  }

  onTimedEvent() {
    if (this.timeTillLate != null) {
      if (this.checksCompleted === this.checksToDo) {
        this.levelWin();
      } else {
        this.timeTillLate -= 1; // One second

        if (this.timeTillLate == 0) this.gameOver();
      }

      if (this.countdownLabel) this.countdownLabel.setText('Time Till Late: ' + this.formatTime(this.timeTillLate));
    }
  }

  formatTime(seconds: number) {
    const minutes: number = Math.floor(seconds / 60);
    const partInSeconds: string = (seconds % 60).toString().padStart(2, '0');

    return `${minutes}:${partInSeconds}`;
  }

  addOverlay() {
    this.checksCompletedLabel = this.add.bitmapText(
      parseInt(this.game.config.width.toString()),
      parseInt(this.game.config.height.toString()),
      "default",
      `Checks Completed: ${this.checksCompleted} of ${this.checksToDo}`,
      15
    );

    this.checksCompletedLabel.x = parseInt(this.game.config.width.toString()) - this.checksCompletedLabel.width - 20;
    this.checksCompletedLabel.y = parseInt(this.game.config.height.toString()) - this.checksCompletedLabel.height - 20;
    this.checksCompletedLabel.setDepth(10);

    if (this.timeTillLate != null) {
      this.countdownLabel = this.add.bitmapText(
        parseInt(this.game.config.width.toString()),
        parseInt(this.game.config.height.toString()),
        "default",
        "Time Till Late: " + this.formatTime(this.timeTillLate),
        15
      );
    }

    if (this.countdownLabel) this.countdownLabel.x = this.checksCompletedLabel.x;
    if (this.countdownLabel) this.countdownLabel.y = this.checksCompletedLabel.y - this.checksCompletedLabel.height - 20;
    if (this.countdownLabel) this.countdownLabel.setDepth(10);

    this.levelLabel = this.add.bitmapText(
      parseInt(this.game.config.width.toString()),
      parseInt(this.game.config.height.toString()),
      "default",
      `Level ${this.levelNum}: ${this.levelName}`,
      15
    );

    if (this.countdownLabel) this.levelLabel.x = this.countdownLabel.x;
    if (this.countdownLabel) this.levelLabel.y = this.countdownLabel.y - this.countdownLabel.height - 20;
    this.levelLabel.setDepth(10);

    this.speakingTextLabel = this.add.bitmapText(
      parseInt(this.game.config.width.toString()),
      parseInt(this.game.config.height.toString()),
      "default",
      '',
      15
    );    
  }

  showSpeakingText(text: string, color: number = 0xFFFFFF, delay: number = 2000) {
    if (this.speakingTextLabel) {
      this.speakingTextLabel.setText(text);
      this.speakingTextLabel.tint = color;  
      if (this.levelLabel) this.speakingTextLabel.x = this.levelLabel.x;
      if (this.levelLabel) this.speakingTextLabel.y = this.levelLabel.y - this.levelLabel.height - 20;

      this.time.addEvent({ delay: delay, callback: () => {if (this.speakingTextLabel) this.speakingTextLabel.setText("")}, callbackScope: this, loop: false });
    }
  }
}
