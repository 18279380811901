import Phaser from "phaser";
import Fonts from "../assets/Fonts";
import InfoScene from "./InfoScene";

export default class PauseScene extends Phaser.Scene {
  constructor() {
    super("PauseScene");
  }

  preload(): void {
    this.load.bitmapFont("default", ...Fonts.default);
  }

  create(): void {
    this.addOverlay();

    this.input.keyboard.on("keydown_SPACE", () => {
      const timedEvent = (this.scene.get('InfoScene') as InfoScene).timedEvent;
      if(timedEvent) timedEvent.paused = false;
      
      this.scene.wake("DungeonScene");
      this.scene.stop();
    });
  }

  addOverlay() {
    this.cameras.main.setBackgroundColor('rgba(100,100,100,0.35)');

    const text = this.add.bitmapText(
      this.cameras.main.centerX, 
      this.cameras.main.centerY,
      "default",
      "Press space to unpause",
      20
    );

    text.x = this.cameras.main.centerX - text.width/2;
    text.y = this.cameras.main.centerY - text.height/2;
    text.setDepth(10);
  }
}
