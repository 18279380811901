import Phaser from "phaser";
import Fonts from "../assets/Fonts";

export default class LeaderboardScene extends Phaser.Scene {
  constructor() {
    super("LeaderboardScene");
  }

  preload(): void {
    this.load.bitmapFont("default", ...Fonts.default);
  }

  create(): void {
    const currentUser = firebase.auth().currentUser;

    if (currentUser) {
      this.addLeaderboard(currentUser);
    }

    this.addButton(
      this.cameras.main.centerX,
      this.cameras.main.centerY + 20,
      "Return to title screen", () => {
        this.scene.get('TitleScene').scene.restart();
        this.scene.stop();
      },
    );
  }

  scoreToLevel(score: number) : number {
    return parseInt(score.toString().split(".")[0]);
  }

  scoreToTimeLeft(score: number) : number {
    return parseInt(score.toString().split(".")[1]);
  }

  addLeaderboard(currentUser: any) {
    const leaderBoard = this.scene.scene.plugins.get('FirebasePlugin').add.leaderBoard({ root: 'mainLeaderboard', pageItemCount: '10' });
    leaderBoard.setUser(currentUser.uid, currentUser.displayName);

    leaderBoard.getScore().then((score: any) => {
      if (score) {
        const agencyName: string = score["agencyName"];
        const userName: string = score["userName"];

        const parsedScore = score["score"].toString().slice(0, -1);
        const level = this.scoreToLevel(parsedScore);
        const timeLeft = this.scoreToTimeLeft(parsedScore);

        const subTitle = `${userName} : ${agencyName}   Level ${level} with ${timeLeft} seconds left`;
        this.setTitle("LEADERBOARD", subTitle);

        //leaderBoard.getRank().then((rank: any) => {}).catch((e: any) => { console.log(e) });
      } else {
        this.setTitle("LEADERBOARD", "Think you can beat these scores? Play now on the titlescreen");
      }
    }).catch((e: any) => { console.log(e) });

    leaderBoard.loadFirstPage().then((scores: any) => {
      const scrollablePanel = this.rexUI.add.scrollablePanel({
        x: this.cameras.main.centerX,
        y: this.cameras.main.centerY + 210,
        width: 700,
        height: 220,
        scrollMode: 0,
        background: this.rexUI.add.roundRectangle(0, 0, 2, 2, 10, 0x323234),
        panel: {
          child: this.rexUI.add.fixWidthSizer({
            space: {
              left: 3,
              right: 3,
              top: 3,
              bottom: 3,
              item: 8,
              line: 8,
            }
          }),
          mask: {
            padding: 1
          },
        },
        slider: {
          track: this.rexUI.add.roundRectangle(0, 0, 20, 10, 10, 0x474749),
          thumb: this.rexUI.add.roundRectangle(0, 0, 0, 0, 13, 0xFFFFFF),
        },
        space: {
          left: 10,
          right: 10,
          top: 10,
          bottom: 10,
          panel: 10,
        }
      }).layout()

      console.log(scores);

      const sizer = scrollablePanel.getElement('panel');
      sizer.clear(true);

      for (var li = 0, lcnt = scores.length; li < lcnt; li++) {
        const agencyName: string = scores[li]["agencyName"];
        const userName: string = scores[li]["userName"];
        const parsedScore = scores[li]["score"].toString().slice(0, -1);
        const level = this.scoreToLevel(parsedScore);
        const timeLeft = this.scoreToTimeLeft(parsedScore);

        sizer.add(
          this.add.text(0, 0, `${userName} : Level ${level} with ${timeLeft} seconds left`, {
            fontSize: 22
          })
        );
        sizer.addNewLine();
        sizer.add(
          this.add.text(0, 0, " " + agencyName, {
            fontSize: 16
          })
        );
        sizer.addNewLine();
        sizer.add(
          this.add.rectangle(
            0,
            0,
            600,
            2,
            0x474749,
            1.0,
          )
        ); 
        if (li < (lcnt - 1)) {
          sizer.addNewLine();
        }
      }


      scrollablePanel.layout();
    }).catch((e: any) => { console.log(e) });
  }

  addButton(x: number, y: number, text: string, onClick: Function) {
    const rectangleWidth = 360;
    const rectangleHeight = 50;
    const rectangleBorder = 5;

    const newButton = this.add.rectangle(
      x,
      y,
      rectangleWidth,
      rectangleHeight,
      0xFFFFFF,
      0.0,
    );

    newButton.setDepth(10);
    newButton.setStrokeStyle(5, 0xFFFFFF);
    newButton.setInteractive({ useHandCursor: true });
    newButton.on('pointerdown', onClick);
    newButton.on('pointerover', () => newButton.fillAlpha = 0.2);
    newButton.on('pointerout', () => newButton.fillAlpha = 0.0);

    const singlePlayerText = this.add.bitmapText(
      x,
      y,
      "default",
      text,
      15
    );

    singlePlayerText.x = x - singlePlayerText.width / 2;
    singlePlayerText.y = y - singlePlayerText.height / 2 + rectangleBorder;
    singlePlayerText.setDepth(9);
  }

  setTitle(title: string, subtitle: string) {
    const titleText = this.add.bitmapText(
      this.cameras.main.centerX,
      this.cameras.main.centerY,
      "default",
      title,
      60
    );

    titleText.x = this.cameras.main.centerX - titleText.width / 2;
    titleText.y = this.cameras.main.centerY - titleText.height / 2 - 120;
    titleText.setDepth(10);

    const subTitleText = this.add.bitmapText(
      this.cameras.main.centerX,
      this.cameras.main.centerY,
      "default",
      subtitle,
      15
    );

    subTitleText.x = this.cameras.main.centerX - subTitleText.width / 2;
    subTitleText.y = this.cameras.main.centerY - subTitleText.height / 2 - 80;
    subTitleText.setDepth(10);
  }
}
