import Phaser, { Sound, Scene } from "phaser";
import Graphics from "../assets/Graphics";
import FOVLayer from "../entities/FOVLayer";
import Player from "../entities/Player";
import Captain from "../entities/Captain";
import Inmate from "../entities/Inmate";
import Map from "../entities/Map";
import { TileType } from "../entities/Tile";
import InfoScene from './InfoScene';

import CheckCompletedSound from "../../assets/audio/checkcompleted.mp3";
import BackgroundMusic from "../../assets/audio/background.ogg";

import BigWorkflowsScreen from "../../assets/BigWorkflowsScreen.png";
import BigWorkflowsScreenLate from "../../assets/BigWorkflowsScreenLate.png";
import LevelData, { levels } from "../LevelData";

export default class DungeonScene extends Phaser.Scene {
  sensorsScanned : number[][];
  lastX: number;
  lastY: number;
  player: Player | null;
  captains: Captain[];
  captainGroup: Phaser.GameObjects.Group | null;
  inmates: Inmate[];
  inmateGroup: Phaser.GameObjects.Group | null;
  fov: FOVLayer | null;
  tilemap: Phaser.Tilemaps.Tilemap | null;
  checkCompletedSound: Sound.BaseSound | null;
  backgroundMusic: Sound.BaseSound | null;
  peekedCells: number[][];
  infoScene : Scene | null;
  levelData: LevelData;
  firstPlay: boolean;

  didUpdates = false;

  preload(): void {
    this.load.image(Graphics.environment.name, Graphics.environment.file);
    this.load.image(Graphics.util.name, Graphics.util.file);
    this.load.image("bigworkflowsscreen", BigWorkflowsScreen);
    this.load.image("bigworkflowsscreenlate", BigWorkflowsScreenLate);
    this.load.audio('checkcompleted', CheckCompletedSound);
    //this.load.audio('background', BackgroundMusic);
    this.load.spritesheet(Graphics.player.name, Graphics.player.file, {
      frameHeight: Graphics.player.height,
      frameWidth: Graphics.player.width
    });
    this.load.spritesheet(Graphics.captain.name, Graphics.captain.file, {
      frameHeight: Graphics.captain.height,
      frameWidth: Graphics.captain.width
    });
    this.load.spritesheet(Graphics.inmate.name, Graphics.inmate.file, {
      frameHeight: Graphics.inmate.height,
      frameWidth: Graphics.inmate.width
    });
  }

  constructor() {
    super("DungeonScene");
    this.lastX = -1;
    this.lastY = -1;
    this.player = null;
    this.fov = null;
    this.tilemap = null;
    this.captains = [];
    this.captainGroup = null;
    this.inmates = [];
    this.inmateGroup = null;
    this.checkCompletedSound = null;
    this.backgroundMusic = null;
    this.infoScene = null;
    this.peekedCells = [];
    this.sensorsScanned = [];
    this.levelData = levels[1];
    this.firstPlay = false;
  }

  reset() {
    this.infoScene = null;
    this.peekedCells = [];
    this.sensorsScanned = [];

    this.events.removeAllListeners("changeChecksCompleted");
  }

  init(data: any): void {
    this.levelData = levels[data.levelNumber];
    if (data.firstPlay != undefined) this.firstPlay = data.firstPlay;
  }

  create(): void {
    this.reset();
    
    if (this.levelData) this.infoScene = this.scene.run("InfoScene", this.levelData).scene;

    this.checkCompletedSound = this.sound.add("checkcompleted");
    /*this.backgroundMusic = this.sound.add("background");
    this.backgroundMusic.play({
      volume: .06,
      loop: true,
    }); */

    Object.values(Graphics.player.animations).forEach(anim => {
      if (!this.anims.get(anim.key)) {
        this.anims.create({
          ...anim,
          frames: this.anims.generateFrameNumbers(
            Graphics.player.name,
            anim.frames
          )
        });
      }
    });

    Object.values(Graphics.captain.animations).forEach(anim => {
      if (!this.anims.get(anim.key)) {
        this.anims.create({
          ...anim,
          frames: this.anims.generateFrameNumbers(
            Graphics.captain.name,
            anim.frames
          )
        });
      }
    });

    Object.values(Graphics.inmate.animations).forEach(anim => {
      if (!this.anims.get(anim.key)) {
        this.anims.create({
          ...anim,
          frames: this.anims.generateFrameNumbers(
            Graphics.inmate.name,
            anim.frames
          )
        });
      }
    });

    const map = new Map(this.levelData.worldTileWidth, this.levelData.worldTileHeight, this, this.levelData);
    this.tilemap = map.tilemap;

    this.fov = new FOVLayer(map);

    this.player = new Player(
      this.tilemap.tileToWorldX(this.levelData.startingX),
      this.tilemap.tileToWorldY(this.levelData.startingY),
      this
    );

    this.add.image(
      this.tilemap.tileToWorldX(this.levelData.bigScreenXTile) + this.levelData.bigScreenXOffset,
      this.tilemap.tileToWorldY(this.levelData.bigScreenYTile) + this.levelData.bigScreenYOffset,
      "bigworkflowsscreen"
    ).setDepth(10);

    this.captains = map.captains;
    this.captainGroup = this.physics.add.group(this.captains.map(s => s.sprite));

    this.inmates = map.inmates;
    this.inmateGroup = this.physics.add.group(this.inmates.map(s => s.sprite));

    this.cameras.main.setRoundPixels(true);
    this.cameras.main.setZoom(3);
    this.cameras.main.setBounds(
      0,
      0,
      map.width * Graphics.environment.width,
      map.height * Graphics.environment.height
    );
    this.cameras.main.startFollow(this.player.sprite);

    this.physics.add.collider(this.player.sprite, map.wallLayer);
    this.physics.add.collider(this.player.sprite, map.doorLayer);
    this.physics.add.collider(this.player.sprite, map.midLayer);

    this.input.keyboard.on("keydown_SPACE", () => {  
      const timedEvent = (this.scene.get('InfoScene') as InfoScene).timedEvent;
      if(timedEvent) timedEvent.paused = true;
  
      this.scene.run("PauseScene");
      this.scene.pause();
      
      if (this.backgroundMusic) this.backgroundMusic.stop();
    });

    /*var x = document.createElement("BUTTON");
    x.setAttribute("align", "center"); 
    x.setAttribute("class", "softKeyboardButton");
    x.onclick = () => this.pressSpace();
    var t = document.createTextNode("SPACE");
    x.appendChild(t);
    document.getElementById("buttons")?.appendChild(x); */

    this.input.keyboard.on("keydown_S", () => {
      const playerPos = this.player?.position;

      if (playerPos != undefined) {
        const pos = this.tilemap?.worldToTileXY(playerPos.x, playerPos.y);

        if (pos != undefined) {
          const tileNeighbors = map?.tiles[pos.y][pos.x].neighbours();

          if (tileNeighbors?.n?.type === TileType.CellDoor || tileNeighbors?.nw?.type === TileType.CellDoor) {
            const northWTile = tileNeighbors?.nw;
            const northTile = tileNeighbors?.n;
            const northETile = tileNeighbors?.ne;
            
            if (northTile && northWTile && northETile) {
              if (this.peekedCellsIncludes(northTile.x, northTile.y) || this.peekedCellsIncludes(northWTile.x, northWTile.y)) {
                if (this.toDoScan(northTile.x, northTile.y) || this.toDoScan(northETile.x, northETile.y)) {
                  if (this.toDoScan(northTile.x, northTile.y)) this.sensorsScanned.push([northTile.x, northTile.y]);
                  if (this.toDoScan(northETile.x, northTile.y)) this.sensorsScanned.push([northETile.x, northETile.y]);

                  this.events.emit('changeChecksCompleted', 1);

                  (this.scene.get('InfoScene') as InfoScene).showSpeakingText("Check Completed", 0x308858);

                  this.checkCompletedSound?.play({
                    volume: 1.0,
                    loop: false,
                  });
                }
              }
            }
          }
        }
      }
    });
  }

  update(time: number, delta: number) {
    this.player!.update();

    if (!this.backgroundMusic?.isPlaying) {
      //this.backgroundMusic?.play();
    }

    const camera = this.cameras.main;

    for (let captain of this.captains) {
      captain.update(time);
    }

    for (let inmate of this.inmates) {
      inmate.update(time);
    }

    const player = new Phaser.Math.Vector2({
      x: this.tilemap!.worldToTileX(this.player!.sprite.body.x),
      y: this.tilemap!.worldToTileY(this.player!.sprite.body.y)
    });

    const bounds = new Phaser.Geom.Rectangle(
      this.tilemap!.worldToTileX(camera.worldView.x) - 1,
      this.tilemap!.worldToTileY(camera.worldView.y) - 1,
      this.tilemap!.worldToTileX(camera.worldView.width) + 2,
      this.tilemap!.worldToTileX(camera.worldView.height) + 2
    );

    this.fov!.update(player, bounds, delta);

    if (!this.didUpdates) this.scene.run("StartCountdownScene", {firstPlay: this.firstPlay});
    if (!this.didUpdates) this.didUpdates = true;
  }

  peekedCellsIncludes(x: number, y: number): boolean {
    return this.peekedCells.filter((v: number[]) => v[0] == x && v[1] == y).length > 0;
  }
  toDoScan(x: number, y: number): boolean {
    if (this.levelData.sensorsToScan.filter((v: number[]) => v[0] == x && v[1] == y).length > 0) {
      return this.sensorsScanned.filter((v: number[]) => v[0] == x && v[1] == y).length < 1;
    }

    return false;
  }
}
