import Phaser, { GameObjects } from "phaser";
import Fonts from "../assets/Fonts";

export default class TitleScene extends Phaser.Scene {
  leaderBoard: any;
  subTitleText: GameObjects.BitmapText | null;

  constructor() {
    super("TitleScene");
    this.subTitleText = null;
  }

  preload(): void {
    this.load.bitmapFont("default", ...Fonts.default);
  }

  create(): void {
    this.leaderBoard = this.scene.scene.plugins.get('FirebasePlugin').add.leaderBoard({ root: 'mainLeaderboard' });
    this.setTitle("CHECKMAN", "By UNIT Innovations");

    firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        if (this.subTitleText && user.displayName) {
          this.subTitleText.setText( `Welcome back ${user.displayName}! Can you beat your last score?`);
          this.subTitleText.x = this.cameras.main.centerX - this.subTitleText.width/2;
        }
        
        this.addOverlay(user);
      } else {
        firebase.auth().signInAnonymously().catch((e: any) => console.log(e));
      }
    });
    
    this.addButton(
      this.cameras.main.centerX, 
      this.cameras.main.centerY + 70, 
      "How to play", () => {
        this.scene.get('HowToPlayScene').scene.restart();
        this.scene.stop();
      },
    );
    this.addButton(
      this.cameras.main.centerX, 
      this.cameras.main.centerY + 135, 
      "Leaderboard", () => {
        this.scene.get('LeaderboardScene').scene.restart();
        this.scene.stop();
      },
    );
    this.add.rectangle(
      this.cameras.main.centerX, 
      this.cameras.main.centerY + 180, 
      360,
      2,
      0x474749,
      1.0,
    );
    this.addButton(
      this.cameras.main.centerX, 
      this.cameras.main.centerY + 225, 
      "Click to email to a friend", () => {
        window.location.href = "mailto:?subject=Checkman%20Game!&body=Check%20out%20this%20awesome%20new%20game%20I%20found%20at%20checkman.unitinnovations.com!";
      },
    );
    this.addInteractiveText(
      this.cameras.main.centerX, 
      this.cameras.main.centerY + 280, 
      "Click to visit unitinnovations.com", 
      () => {
        window.location.href = "http://unitinnovations.com/";
      },
    );
  }

  addOverlay(user: any) {
    this.leaderBoard.setUser(user.uid, user.displayName);

    this.leaderBoard.getScore().then((oldScore: any) => {
      this.addButton(
        this.cameras.main.centerX, 
        this.cameras.main.centerY + 5, 
        "Click here to play", () => {
          this.scene.get('DungeonScene').scene.restart({levelNumber: 1, firstPlay: !oldScore});
          this.scene.stop(); 
        },
      );  
    }).catch((e: any) => { console.log(e) });
  }

  addButton(x: number, y: number, text: string, onClick: Function) {
    const rectangleWidth = 360;
    const rectangleHeight = 50;
    const rectangleBorder = 5;

    const newButton = this.add.rectangle(
      x, 
      y,
      rectangleWidth,
      rectangleHeight,
      0xFFFFFF,
      0.0,
    );

    newButton.setDepth(10);
    newButton.setStrokeStyle(5, 0xFFFFFF);
    newButton.setInteractive({ useHandCursor: true });
    newButton.on('pointerdown', onClick);
    newButton.on('pointerover', () => newButton.fillAlpha = 0.2);
    newButton.on('pointerout', () => newButton.fillAlpha = 0.0);

    const singlePlayerText = this.add.bitmapText(
      x, 
      y,
      "default",
      text,
      15
    );

    singlePlayerText.x = x - singlePlayerText.width/2;
    singlePlayerText.y = y - singlePlayerText.height/2 + rectangleBorder;
    singlePlayerText.setDepth(9);
  }

  addInteractiveText(x: number, y: number, text: string, onClick: Function) {
    const singlePlayerText = this.add.bitmapText(
      x, 
      y,
      "default",
      text,
      12
    );

    singlePlayerText.x = x - singlePlayerText.width/2;
    singlePlayerText.y = y - singlePlayerText.height/2;
    singlePlayerText.setDepth(9);
    singlePlayerText.setInteractive({ useHandCursor: true });
    singlePlayerText.on('pointerdown', onClick);
  }

  setTitle(title: string, subtitle: string) {
    const titleText = this.add.bitmapText(
      this.cameras.main.centerX, 
      this.cameras.main.centerY,
      "default",
      title,
      60
    );

    titleText.x = this.cameras.main.centerX - titleText.width/2;
    titleText.y = this.cameras.main.centerY - titleText.height/2 - 120;
    titleText.setDepth(10);

    this.subTitleText = this.add.bitmapText(
      this.cameras.main.centerX, 
      this.cameras.main.centerY,
      "default",
      subtitle,
      15
    );

    this.subTitleText.x = this.cameras.main.centerX - this.subTitleText.width/2;
    this.subTitleText.y = this.cameras.main.centerY - this.subTitleText.height/2 - 80;
    this.subTitleText.setDepth(10);
  }
}
