import Tile, { TileType } from "./Tile";
import Captain from "./Captain";
import Inmate from "./Inmate";
import Graphics from "../assets/Graphics";
import DungeonScene from "../scenes/DungeonScene";
import LevelData from "../LevelData";
import InfoScene from "../scenes/InfoScene";

export default class Map {
  public readonly tiles: Tile[][];
  public readonly width: number;
  public readonly height: number;
  public readonly tilemap: Phaser.Tilemaps.Tilemap;
  public readonly wallLayer: Phaser.Tilemaps.StaticTilemapLayer;
  public readonly doorLayer: Phaser.Tilemaps.DynamicTilemapLayer;
  public readonly midLayer: Phaser.Tilemaps.StaticTilemapLayer;

  public readonly captains: Captain[];
  public readonly inmates: Inmate[];
  
  public readonly levelData: any;

  public readonly midLayerTileTypes: TileType[] = [TileType.BedTop, TileType.BedBottom, TileType.Counter, TileType.CrateDouble, TileType.Crate];

  constructor(width: number, height: number, scene: DungeonScene, levelData: LevelData) {    
    this.width = width;
    this.height = height;

    this.levelData = levelData;

    this.tiles = [];
    if (levelData) {
      for (let y = 0; y < height; y++) {
        this.tiles.push([]);
        for (let x = 0; x < width; x++) {
          const tileType = Tile.tileTypeFor(levelData.tileData[x][y]);
          this.tiles[y][x] = new Tile(tileType, x, y, this);
        }
      }
    }

    this.tilemap = scene.make.tilemap({
      tileWidth: Graphics.environment.width,
      tileHeight: Graphics.environment.height,
      width: width,
      height: height
    });

    const dungeonTiles = this.tilemap.addTilesetImage(
      Graphics.environment.name,
      Graphics.environment.name,
      Graphics.environment.width,
      Graphics.environment.height,
      Graphics.environment.margin,
      Graphics.environment.spacing
    );

    const groundLayer = this.tilemap
      .createBlankDynamicLayer("Ground", dungeonTiles, 0, 0)
      .randomize(
        0,
        0,
        this.width,
        this.height,
        this.levelData.tileGraphics,
      );

    this.captains = [];
    this.inmates = [];

    if (levelData) this.addCaptains(-8, -13, levelData.captainCoordinates, scene);
    if (levelData) this.addInmates(-7.5, -15, levelData.inmateCoordinates, scene);
    
    this.tilemap.convertLayerToStatic(groundLayer).setDepth(1);

    const wallLayer = this.tilemap.createBlankDynamicLayer(
      "Wall",
      dungeonTiles,
      0,
      0
    );

    this.doorLayer = this.tilemap.createBlankDynamicLayer(
      "Door",
      dungeonTiles,
      0,
      0
    );

    const midLayer = this.tilemap.createBlankDynamicLayer(
      "Mid",
      dungeonTiles,
      0,
      0
    );

    for (let x = 0; x < width; x++) {
      for (let y = 0; y < height; y++) {
        const tile = this.tiles[y][x];
        if (tile.type === TileType.Wall) {
          wallLayer.putTileAt(tile.spriteIndex(), x, y);
        } else if (tile.type === TileType.Door) {
          this.doorLayer.putTileAt(tile.spriteIndex(), x, y);
        } else if (tile.type === TileType.CellDoor) {
          this.doorLayer.putTileAt(tile.spriteIndex(), x, y);
        } else if (this.midLayerTileTypes.includes(tile.type)) {
          midLayer.putTileAt(tile.spriteIndex(), x, y);
        }
      }
    }
    wallLayer.setCollisionBetween(0, 0x7f);
    wallLayer.setCollisionBetween(0x99, 0x9B);
    midLayer.setCollisionBetween(0x96, 0x9E);

    const seethroughDoors = [
      Graphics.environment.indices.celldoors.horizontal,
    ];

    this.doorLayer.setCollision(seethroughDoors);
    this.doorLayer.setTileIndexCallback(
      [Graphics.environment.indices.celldoors.horizontal],
      (_: unknown, t: Phaser.Tilemaps.Tile) => {
        //console.log(t.tilemapLayer.getTileAt(t.x, t.y).set);
        this.seeTiles([[t.x-1, t.y], [t.x-1, t.y-1], [t.x-1, t.y-2], [t.x-1, t.y-3], [t.x, t.y-1], [t.x, t.y-2], [t.x, t.y-3], [t.x+1, t.y-1], [t.x+1, t.y-2], [t.x+1, t.y-3], [t.x+2, t.y-1], [t.x+2, t.y-2], [t.x+2, t.y-3] ]);
        if (scene.peekedCells.filter((v : number[]) => v[0] == t.x && v[1] == t.y).length === 0) scene.peekedCells.push([t.x, t.y]);
        scene.fov!.recalculate();
      },
      this
    );

    const destroyableDoors = [
      Graphics.environment.indices.doors.horizontal,
      Graphics.environment.indices.doors.vertical,
    ];
    this.doorLayer.setCollision(destroyableDoors);
    this.doorLayer.setTileIndexCallback(
      destroyableDoors,
      (_: unknown, tile: Phaser.Tilemaps.Tile) => {
        this.doorLayer.putTileAt(
          Graphics.environment.indices.doors.destroyed,
          tile.x,
          tile.y
        );
        this.tileAt(tile.x, tile.y)!.open();
        scene.fov!.recalculate();
      },
      this
    );
    this.doorLayer.setDepth(3);

    this.wallLayer = this.tilemap.convertLayerToStatic(wallLayer);
    this.wallLayer.setDepth(2);

    this.midLayer = this.tilemap.convertLayerToStatic(midLayer);
    this.midLayer.setDepth(3);
  }

  addInmates(offsetX: number, offsetY: number, tileLocations: number[][], scene: DungeonScene): void {
    tileLocations.forEach(tileLocation => {
      this.inmates.push(
        new Inmate(
          this.tilemap.tileToWorldX(tileLocation[0]) + offsetX,
          this.tilemap.tileToWorldX(tileLocation[1]) + offsetY,
          scene
        )
      );
    });
  }

  addCaptains(offsetX: number, offsetY: number, tileLocations: number[][], scene: DungeonScene): void {
    tileLocations.forEach(tileLocation => {
      this.captains.push(
        new Captain(
          this.tilemap.tileToWorldX(tileLocation[0]) + offsetX,
          this.tilemap.tileToWorldX(tileLocation[1]) + offsetY,
          scene
        )
      );
    });
  }

  seeTiles(tiles: Array<Array<number>>): void {
    tiles.forEach(t => {
      let foundTile = this.tileAt(t[0], t[1])

      if (foundTile) foundTile.seen = true;
      if (foundTile) foundTile.desiredAlpha = 0.0;
    });
  }

  tileAt(x: number, y: number): Tile | null {
    if (y < 0 || y >= this.height || x < 0 || x >= this.width) {
      return null;
    }
    return this.tiles[y][x];
  }
}
