import Phaser from "phaser";
import Fonts from "../assets/Fonts";
import {levels} from "../LevelData";

export default class HowToPlayScene extends Phaser.Scene {
  constructor() {
    super("HowToPlayScene");
  }

  preload(): void {
    this.load.bitmapFont("default", ...Fonts.default);
  }

  create(): void {
    this.addPanel();

    this.setTitle("HOW TO PLAY", "Slide down the circle to read more");

    this.addButton(
      this.cameras.main.centerX,
      this.cameras.main.centerY - 15,
      "Continue to play", () => {
        this.scene.get('DungeonScene').scene.restart({levelNumber: 1});
        this.scene.stop();
      },
    );

    this.addButton(
      this.cameras.main.centerX,
      this.cameras.main.centerY + 50,
      "Return to title screen", () => {
        this.scene.get('TitleScene').scene.restart();
        this.scene.stop();
      },
    );
  }

  addPanel() {
    const scrollablePanel = this.rexUI.add.scrollablePanel({
      x: this.cameras.main.centerX,
      y: this.cameras.main.centerY + 210,
      width: 700,
      height: 220,
      scrollMode: 0,
      background: this.rexUI.add.roundRectangle(0, 0, 2, 2, 10, 0x323234),
      panel: {
        child: this.rexUI.add.fixWidthSizer({
          space: {
              left: 3,
              right: 3,
              top: 3,
              bottom: 3,
              item: 8,
              line: 8,
          }
        }),
        mask: {
          padding: 1
        },
      },
      slider: {
          track: this.rexUI.add.roundRectangle(0, 0, 20, 10, 10, 0x474749),
          thumb: this.rexUI.add.roundRectangle(0, 0, 0, 0, 13, 0xFFFFFF),
      },
      space: {
          left: 10,
          right: 10,
          top: 10,
          bottom: 10,
          panel: 10,
      }
    }).layout()
  
    const sizer = scrollablePanel.getElement('panel');

    //TODO => FIXES: Might make them think we build correctional facilities?
    const content = `You are an officer in UNIT Correctional Facility.\nYou have a UNIT Device and there are sensors placed around the map next to cells.\n\nYour goal is to check on all the inmates and scan all the sensors on the map before the time runs out.\n\n1. To move use your up, down, left, and right arrow keys.\n2. To check on an inmate walk up to the bars and press the up arrow key.\n3. To scan a sensor get next to it and press the S key. Note: you can not scan a sensor before checking on an inmate.\n\nThere are currently ${levels.length - 1} levels of CHECKMAN. Try and beat all ${levels.length - 1} levels in the fastest time and try to reach the top of the leaderboard!`;

    sizer.clear(true);
    const lines = content.split('\n');
    for (let li = 0, lcnt = lines.length; li < lcnt; li++) {
      const words = lines[li].split(' ');
      for (let wi = 0, wcnt = words.length; wi < wcnt; wi++) {
        sizer.add(
          this.add.text(0, 0, words[wi], {
              fontSize: 18
          })
        );
      }
      if (li < (lcnt - 1)) {
        sizer.addNewLine();
      }
    }


    scrollablePanel.layout();
  }

  addButton(x: number, y: number, text: string, onClick: Function) {
    const rectangleWidth = 360;
    const rectangleHeight = 50;
    const rectangleBorder = 5;

    const newButton = this.add.rectangle(
      x,
      y,
      rectangleWidth,
      rectangleHeight,
      0xFFFFFF,
      0.0,
    );

    newButton.setDepth(10);
    newButton.setStrokeStyle(5, 0xFFFFFF);
    newButton.setInteractive({ useHandCursor: true });
    newButton.on('pointerdown', onClick);
    newButton.on('pointerover', () => newButton.fillAlpha = 0.2);
    newButton.on('pointerout', () => newButton.fillAlpha = 0.0);

    const singlePlayerText = this.add.bitmapText(
      x,
      y,
      "default",
      text,
      15
    );

    singlePlayerText.x = x - singlePlayerText.width / 2;
    singlePlayerText.y = y - singlePlayerText.height / 2 + rectangleBorder;
    singlePlayerText.setDepth(9);
  }

  setTitle(title: string, subtitle: string) {
    const titleText = this.add.bitmapText(
      this.cameras.main.centerX,
      this.cameras.main.centerY,
      "default",
      title,
      60
    );

    titleText.x = this.cameras.main.centerX - titleText.width / 2;
    titleText.y = this.cameras.main.centerY - titleText.height / 2 - 120;
    titleText.setDepth(10);

    const subTitleText = this.add.bitmapText(
      this.cameras.main.centerX,
      this.cameras.main.centerY,
      "default",
      subtitle,
      15
    );

    subTitleText.x = this.cameras.main.centerX - subTitleText.width / 2;
    subTitleText.y = this.cameras.main.centerY - subTitleText.height / 2 - 80;
    subTitleText.setDepth(10);
  }
}
