import Map from "./map";
import Graphics from "../assets/Graphics";

export enum TileType {
  None,
  Wall,
  Door,
  CellDoor,
  BedTop,
  BedBottom,
  Counter,
  CrateDouble,
  Crate
}

export default class Tile {
  public collides: boolean;
  public readonly type: TileType;
  public readonly map: Map;
  public readonly x: number;
  public readonly y: number;
  public seen: boolean;
  public desiredAlpha: number; // TODO: Move out of this class, specific to FOV

  public static tileTypeFor(type: string): TileType {
    if (type === "wall") {
      return TileType.Wall;
    } else if (type === "door") {
      return TileType.Door;
    } else if (type === "cell-door") {
      return TileType.CellDoor;
    } else if (type === "bed-top") {
      return TileType.BedTop;
    } else if (type === "bed-bottom") {
      return TileType.BedBottom;
    } else if (type === "counter") {
      return TileType.Counter;
    } else if (type === "crate") {
      return TileType.Crate;
    } else if (type === "crate-double") {
      return TileType.CrateDouble;
    } else {
      return TileType.None;
    }
  }

  constructor(type: TileType, x: number, y: number, map: Map) {
    this.type = type;
    this.collides = type !== TileType.None;
    this.map = map;
    this.x = x;
    this.y = y;
    this.seen = false;
    this.desiredAlpha = 1;
  }

  open() {
    this.collides = false;
  }

  neighbours(): { [dir: string]: Tile | null } {
    return {
      n: this.map.tileAt(this.x, this.y - 1),
      s: this.map.tileAt(this.x, this.y + 1),
      w: this.map.tileAt(this.x - 1, this.y),
      e: this.map.tileAt(this.x + 1, this.y),
      nw: this.map.tileAt(this.x - 1, this.y - 1),
      ne: this.map.tileAt(this.x + 1, this.y - 1),
      sw: this.map.tileAt(this.x - 1, this.y + 1),
      se: this.map.tileAt(this.x + 1, this.y + 1)
    };
  }

  spriteIndex(): number {
    const modifier = this.type === TileType.Wall && this.map.levelData.wallShade == 'grey' ? 8 : 0;
    return this.rawIndex() + modifier;
  }

  // prettier-ignore
  private rawIndex(): number {
    const neighbours = this.neighbours();

    const n = neighbours.n && neighbours.n.type === TileType.Wall;
    const s = neighbours.s && neighbours.s.type === TileType.Wall;
    const w = neighbours.w && neighbours.w.type === TileType.Wall;
    const e = neighbours.e && neighbours.e.type === TileType.Wall;

    const nCounter = neighbours.n && neighbours.n.type === TileType.Counter;
    const sCounter = neighbours.s && neighbours.s.type === TileType.Counter;
    const wCounter = neighbours.w && neighbours.w.type === TileType.Counter;
    const eCounter = neighbours.e && neighbours.e.type === TileType.Counter;

    const wCellDoor = neighbours.w && neighbours.w.type === TileType.CellDoor
    const eCellDoor = neighbours.e && neighbours.e.type === TileType.CellDoor

    const wDoor = neighbours.w && neighbours.w.type === TileType.Door;
    const eDoor = neighbours.e && neighbours.e.type === TileType.Door;

    const indices = Graphics.environment.indices;

    if (this.type === TileType.Wall) {
      const i = indices.walls;

      if (n && e && s && w) { return i.intersections.n_e_s_w; }
      if (n && e && s) { return i.intersections.n_e_s; }
      if (n && s && w) { return i.intersections.n_s_w; }
      if (e && s && w) { return i.intersections.e_s_w; }
      if (n && e && w) { return i.intersections.n_e_w; }

      if (e && s) { return i.intersections.e_s; }
      if (e && w) { return i.intersections.e_w; }
      if (s && w) { return i.intersections.s_w; }
      if (n && s) { return i.intersections.n_s; }
      if (n && e) { return i.intersections.n_e; }
      if (n && w) { return i.intersections.n_w; }

      if (w && eDoor) { return i.intersections.e_door; }
      if (e && wDoor) { return i.intersections.w_door; }

      if (w && eCellDoor) { return i.intersections.e_door_sensor; }
      if (e && wCellDoor) { return i.intersections.w_door_sensor; } //CellDoor to the west of this wall

      if (n) { return i.intersections.n; }
      if (s) { return i.intersections.s; }
      if (e) { return i.intersections.e; }
      if (w) { return i.intersections.w; }

      return i.alone;
    }

    if (this.type === TileType.Door) {
      if (n || s) {
        return indices.doors.vertical
      } else {
        return indices.doors.horizontal;
      }
    }

    if (this.type === TileType.CellDoor) {
      if (n || s) {
        return indices.celldoors.vertical
      } else {
        return indices.celldoors.horizontal;
      }
    }

    if (this.type === TileType.BedTop) {
      return indices.bed.top;
    }

    if (this.type === TileType.BedBottom) {
      return indices.bed.bottom;
    }

    if (this.type === TileType.CrateDouble) {
      return indices.crate.double;
    }

    if (this.type === TileType.Crate) {
      return indices.crate.single;
    }


    if (this.type === TileType.Counter) {
      if (nCounter && wCounter) { return indices.counters.intersections.n_w; }
      if (eCounter && wCounter || eCounter || wCounter) { return indices.counters.intersections.e_w; }
      if (sCounter) { return indices.counters.intersections.s; }

      return indices.counters.alone;
    }

    return 0;
  }
}
