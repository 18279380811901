import Phaser, { Types, Sound, GameObjects } from "phaser";
import Fonts from "../assets/Fonts";
import InfoScene from "./InfoScene";
import DungeonScene from "./DungeonScene";
import WorkflowDueSoonSound from "../../assets/audio/duesoon.mp3";

export default class StartCountdownScene extends Phaser.Scene {
  timedEvent: Types.Time.TimerEventConfig | null;
  titleText: GameObjects.BitmapText | null;
  subTitleText: GameObjects.BitmapText | null;
  dueSoonSound: Sound.BaseSound | null;
  timeTillStart = 3;
  firstPlay: boolean;

  constructor() {
    super("StartCountdownScene");
    this.titleText = null;
    this.subTitleText = null;
    this.timedEvent = null;
    this.dueSoonSound = null;
    this.firstPlay = false;
  }

  preload(): void {
    this.load.audio('duesoon', WorkflowDueSoonSound);
    this.load.bitmapFont("default", ...Fonts.default);
  }

  init(data: any): void {
    if (data.firstPlay != undefined) this.firstPlay = data.firstPlay;
  }

  addButton(x: number, y: number, text: string, onClick: Function): GameObjects.Group {
    const rectangleWidth = 360;
    const rectangleHeight = 50;
    const rectangleBorder = 5;

    const newButton = this.add.rectangle(
      x,
      y,
      rectangleWidth,
      rectangleHeight,
      0xFFFFFF,
      0.0,
    );

    newButton.setDepth(10);
    newButton.setStrokeStyle(5, 0xFFFFFF);
    newButton.setInteractive({ useHandCursor: true });
    newButton.on('pointerdown', onClick);
    newButton.on('pointerover', () => newButton.fillAlpha = 0.2);
    newButton.on('pointerout', () => newButton.fillAlpha = 0.0);

    const singlePlayerText = this.add.bitmapText(
      x,
      y,
      "default",
      text,
      15
    );

    singlePlayerText.x = x - singlePlayerText.width / 2;
    singlePlayerText.y = y - singlePlayerText.height / 2 + rectangleBorder;
    singlePlayerText.setDepth(9);

    return new GameObjects.Group(this, [newButton, singlePlayerText]);
  }

  create(): void {
    this.cameras.main.setBackgroundColor('rgba(100,100,100,0.35)');

    this.dueSoonSound = this.sound.add("duesoon");

    (this.scene.get('DungeonScene') as DungeonScene).scene.pause();
    const lateTimedEvent = (this.scene.get('InfoScene') as InfoScene).timedEvent;
    if (lateTimedEvent) lateTimedEvent.paused = true;

    if (this.firstPlay) {
      this.setTitle("HOW TO PLAY", "1. To move use your up, down, left, and right arrow keys.\n2. To check on an inmate walk up to the bars and press the up arrow key.\n3. To scan a sensor get next to it and press the S key.\n    Note: you can not scan a sensor before checking on an inmate.");
      
      if (this.subTitleText) {
        const understandButtonGroup = this.addButton(
          this.cameras.main.centerX,
          this.subTitleText.y + this.subTitleText.height + 65,
          "I understand, let's, go!",
          () => {
            if (this.titleText) this.titleText.destroy();
            if (this.subTitleText) this.subTitleText.destroy();
            understandButtonGroup.getChildren().forEach((c: GameObjects.GameObject) => c.destroy());

            this.setTitle(`GAME STARTS IN ${this.timeTillStart}`, "");
            this.timedEvent = this.time.addEvent({ delay: 1000, callback: this.onTimedEvent, callbackScope: this, repeat: 3 });
          },
        );
      }
    } else {
      this.setTitle(`GAME STARTS IN ${this.timeTillStart}`, "");
      this.timedEvent = this.time.addEvent({ delay: 1000, callback: this.onTimedEvent, callbackScope: this, repeat: 3 });
    }
  }

  onTimedEvent() {
    this.timeTillStart -= 1;
    this.titleText?.setText(`GAME STARTS IN ${this.timeTillStart}`);

    const newGrey = this.timeTillStart*31;

    this.cameras.main.setBackgroundColor(`rgba(${newGrey},${newGrey},${newGrey},0.35)`);

    console.log(`rgba(${newGrey},${newGrey},${newGrey},0.35)`);

    if (this.timeTillStart == 0) {
      const lateTimedEvent = (this.scene.get('InfoScene') as InfoScene).timedEvent;
      if(lateTimedEvent) lateTimedEvent.paused = false;

      (this.scene.get('InfoScene') as InfoScene).showSpeakingText("Due soon!", 0xF9E94E);

      this.dueSoonSound?.play({
        volume: 1.0,
        loop: false,
      });
      
      this.scene.wake("DungeonScene");
      this.scene.stop();
    }
  }

  setTitle(title: string, subtitle: string) {
    this.titleText = this.add.bitmapText(
      this.cameras.main.centerX,
      this.cameras.main.centerY,
      "default",
      title,
      60
    );

    this.titleText.x = this.cameras.main.centerX - this.titleText.width / 2;
    this.titleText.y = this.cameras.main.centerY - this.titleText.height / 2 - 120;
    this.titleText.setDepth(10);

    this.subTitleText = this.add.bitmapText(
      this.cameras.main.centerX,
      this.cameras.main.centerY,
      "default",
      subtitle,
      15,
      1
    );

    this.subTitleText.x = this.cameras.main.centerX - this.subTitleText.width / 2;
    this.subTitleText.y = this.cameras.main.centerY - this.subTitleText.height / 6 - 20;
    this.subTitleText.setDepth(10);
  }
}
