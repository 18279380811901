import Phaser from "phaser";
import DungeonScene from "./scenes/DungeonScene";
import InfoScene from "./scenes/InfoScene";
import PauseScene from "./scenes/PauseScene";
import TitleScene from "./scenes/TitleScene";
import LeaderboardScene from "./scenes/LeaderboardScene";
import HowToPlayScene from "./scenes/HowToPlayScene";
import StartCountdownScene from "./scenes/StartCountdownScene";
import FirebasePlugin from "./plugins/rexfirebaseplugin.min.js";
import InputTextPlugin from "./plugins/rexinputtextplugin.min.js";
import RexUIPlugin from "./plugins/rexuiplugin.min.js";
// import SceneWatcherPlugin from "phaser-plugin-scene-watcher";

if (window.innerWidth > 500) {
  new Phaser.Game({
    type: Phaser.WEBGL,
    width: window.innerWidth,
    height: window.innerHeight,
    //height: window.innerWidth < 500 ? window.innerHeight * 7/10 : window.innerHeight,
    render: { pixelArt: true },
    physics: { default: "arcade", arcade: { debug: false, gravity: { y: 0 } } },
    scene: [TitleScene, DungeonScene, InfoScene, PauseScene, LeaderboardScene, HowToPlayScene, StartCountdownScene],
    scale: {
      mode: Phaser.Scale.NONE
    },
    audio: {
      disableWebAudio: false,
    },
    parent: "main",
    dom: {
        createContainer: true
    },
    plugins: {
      global: [
        { key: "FirebasePlugin", plugin: FirebasePlugin, start: true },
        { key: 'InputTextPlugin', plugin: InputTextPlugin, start: true },
      ],
      scene: [
        { key: 'RexUIPlugin', plugin: RexUIPlugin, mapping: 'rexUI' },
      ]
    }
  });
} else {
  const paragraph = document.createElement("p");
  const needKeyboard = document.createTextNode("Right now Checkman doesn't work on smaller screens without a keyboard. Check this site out on your computer to play!");
  paragraph.appendChild(needKeyboard);
  paragraph.style.textAlign = "center";
  paragraph.style.padding = "50px";

  const main = document.getElementById("main");

  if (main) {
    console.log(main);

    main.appendChild(paragraph);
    main.style.display = "flex";
    main.style.justifyContent = "center"; 
    main.style.height = "100vh"; 
    main.style.alignItems = "center";
  }
}